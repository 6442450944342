<template>
  <div>
    <global-header></global-header>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
export default {
  name: "WrapLayout",
  components: {
    GlobalHeader: () => import('@PAGE/common/GlobalHeader'),
    GlobalFooter: () => import('@PAGE/common/GlobalFooter'),
    FooterLinks: () => import('@PAGE/common/FooterLinks'),
  },
}
</script>

<style scoped lang="scss">

</style>
